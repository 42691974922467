<template>
  <div>
    <b-container fluid class="borderColor borderColorBackground">
      <b-row
        :no-gutters="true"
        style="display: flex; flex-wrap: wrap; justify-content: center"
      >
        <div class="time-group" style="display: flex; flex-wrap: wrap">
          <div style="display: flex; align-items: center; margin-right: 10px">
            選擇{{ timeToText }}份:
            <date-picker
              v-model="datepickerDate"
              :type="selectedTime"
              placeholder="請選擇"
              valueType="format"
              :format="timeFormat"
              style="width: 120px"
              :disabled-date="notAfterToday"
            ></date-picker>
          </div>
          <button
            class="datepickerButton"
            :class="{ 'date-active': selectedTime === 'month' }"
            @click="handleSelectTime('month')"
          >
            {{ $t("dataAnalysis.month") }}
          </button>
          <button
            class="datepickerButton"
            :class="{ 'date-active': selectedTime === 'year' }"
            @click="handleSelectTime('year')"
          >
            {{ $t("dataAnalysis.year") }}
          </button>
          <button
            class="confirmButton"
            style="margin-left: 10px"
            @click="getSearch(selectedTime)"
          >
            {{ $t("deviceStatus.confirm") }}
          </button>
        </div>
      </b-row>
    </b-container>
    <b-container fluid>
      <div
        class="othertableStyle borderColor sideTable"
        style="margin: 15px 0px; display: flex"
      >
        <table>
          <tr>
            <td>本{{ searchedTime }}累計日照量 (kWh/ m2)</td>
            <td>{{ dbDataHandle(sumTableObj.accIRR) }}</td>
          </tr>
          <tr>
            <td>本{{ searchedTime }}累計峰瓩值 (kWh/ kWp)</td>
            <td>{{ dbDataHandle(sumTableObj.peakWatt) }}</td>
          </tr>
          <tr>
            <td>本{{ searchedTime }}實際發電量 (kWh)</td>
            <td>{{ dbDataHandle(sumTableObj.accKwh) }}</td>
          </tr>
          <tr>
            <td>本{{ searchedTime }}平均發電效率 (%)</td>
            <td>{{ dbDataHandle(sumTableObj.accPR.all * 100) }}</td>
          </tr>
        </table>
        <div style="width: 100%">
          <div style="display: flex; align-items: center; margin: 5px">
            <button
              class="confirmButton"
              style="width: 120px; margin-left: auto"
              @click="downloadData"
            >
              資料下載
            </button>
          </div>
          <highcharts
            :options="monthsChart"
            ref="chart"
            style="background: white; min-height: 350px"
          ></highcharts>
        </div>
      </div>
      <div
        class="othertableStyle borderColor"
        style="margin: 15px 0px; display: flex"
      >
        <table>
          <tr>
            <th>時間</th>
            <th>發電量 (kWh)</th>
            <th>峰瓩值 (kWh/kWp)</th>
            <th>日照量 (kWh/m2)</th>
            <th>發電效率 (%)</th>
            <th>事件備註</th>
          </tr>
          <tr v-for="(i, idx) in tableData" :key="idx">
            <td>{{ i.date }}</td>
            <td>
              {{ !dbDataHandle(i.accKwh) ? "-" : dbDataHandle(i.accKwh) }}
            </td>
            <td>
              {{ !dbDataHandle(i.peakWatt) ? "-" : dbDataHandle(i.peakWatt) }}
            </td>
            <td>
              {{ !dbDataHandle(i.accIRR) ? "-" : dbDataHandle(i.accIRR) }}
            </td>
            <td>
              {{
                !dbDataHandle(i.accPR * 100) ? "-" : dbDataHandle(i.accPR * 100)
              }}
            </td>
            <td>-</td>
          </tr>
          <tr>
            <td>累積</td>
            <td>{{ tableAccObj.kWh }}</td>
            <td>{{ tableAccObj.peakWatt }}</td>
            <td>{{ tableAccObj.IRR }}</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>平均</td>
            <td>{{ tableAvgObj.kWh }}</td>
            <td>{{ tableAvgObj.peakWatt }}</td>
            <td>{{ tableAvgObj.IRR }}</td>
            <td>{{ tableAvgObj.PR }}</td>
            <td>-</td>
          </tr>
        </table>
      </div>
      <div style="height: 25px"></div>
    </b-container>
  </div>
</template>
<script>
import { DateTime } from 'luxon'
import downloadCsv from 'download-csv'
import {
  getMonSumTableApi,
  getDaysTableApi,
  getDaysChartApi,
  getYearSumTableApi,
  getMonthsTableApi,
  getMonthsChartApi
} from '../api'
import { dbDataHandle } from '../store/function/common/index'
export default {
  name: 'dataanalysis',
  data () {
    return {
      tableAccObj: {
        kWh: 0,
        peakWatt: 0,
        IRR: 0,
        PR: 0
      },
      tableAvgObj: {
        kWh: 0,
        peakWatt: 0,
        IRR: 0,
        PR: 0
      },
      sumTableObj: {
        accIRR: '-',
        peakWatt: '-',
        accKwh: '-',
        accPR: {
          all: '-'
        }
      },
      tableData: [],
      chartData: [],
      datepickerDate: DateTime.local().minus({ months: 1 }).toFormat('yyyy-MM'),
      selectedTime: 'month',
      searchedTime: '月',
      monthsChart: {
        chart: {
          events: {
            load () {
              this.showLoading()
            }
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: []
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false
        },
        yAxis: [
          {
            title: {
              text: '發電量 (kWh)'
            }
          },
          {
            title: {
              text: '日照量 (kWh/ m2)'
            },
            opposite: true
          },
          {
            title: {
              text: '發電效率 (%)'
            },
            opposite: true,
            min: 0
          }
        ],
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        labels: {},
        tooltip: {
          shared: true
        },
        series: [
          {
            type: 'column',
            name: '發電量 (kWh)',
            data: [],
            yAxis: 0
          },
          {
            type: 'line',
            name: '日照量 (kWh/ m2)',
            data: [],
            yAxis: 1
          },
          {
            type: 'line',
            name: '發電效率 (%)',
            data: [],
            yAxis: 2
          }
        ]
      }
    }
  },
  computed: {
    timeFormat () {
      return this.selectedTime === 'month' ? 'YYYY-MM' : 'YYYY'
    },
    timeToText () {
      return this.selectedTime === 'month' ? '月' : '年'
    }
  },
  methods: {
    dbDataHandle (value) {
      return dbDataHandle(value)
    },
    handleSelectTime (time) {
      this.selectedTime = time
      this.datepickerDate =
        time === 'month'
          ? DateTime.local().minus({ months: 1 }).toFormat('yyyy-MM')
          : DateTime.local().minus({ year: 1 }).toFormat('yyyy')
    },
    notAfterToday (date) {
      return date > new Date()
    },
    async getSearch (time) {
      if (time === 'month') {
        await Promise.all([
          this.getMonthSumData(),
          this.getDaysTableData(),
          this.getDaysChartData()
        ])
        this.searchedTime = '月'
      } else {
        await Promise.all([
          this.getYearSumData(),
          this.getMonthsTableData(),
          this.getMonthsChartData()
        ])
        this.searchedTime = '年'
      }
    },
    async getMonthSumData () {
      const monSumTable = await getMonSumTableApi(
        this.$router.history.current.query.fid,
        this.datepickerDate
      )
      if (monSumTable?.data?.success) {
        this.sumTableObj = monSumTable.data.data
      }
    },
    async getDaysTableData () {
      const tableData = await getDaysTableApi(
        this.$router.history.current.query.fid,
        this.datepickerDate
      )
      if (tableData?.data?.success) {
        this.tableData = tableData.data.data
        this.calculateTableData()
      }
    },
    async getDaysChartData () {
      const dayschart = await getDaysChartApi(
        this.$router.history.current.query.fid,
        this.datepickerDate
      )
      if (dayschart?.data?.success) {
        this.chartData = dayschart.data.data
        this.drawChart()
      }
    },
    async getYearSumData () {
      const yearSumTable = await getYearSumTableApi(
        this.$router.history.current.query.fid,
        this.datepickerDate
      )
      if (yearSumTable?.data?.success) {
        this.sumTableObj = yearSumTable.data.data
      }
    },
    async getMonthsTableData () {
      const monthsTable = await getMonthsTableApi(
        this.$router.history.current.query.fid,
        this.datepickerDate
      )
      if (monthsTable?.data?.success) {
        this.tableData = monthsTable.data.data
        this.calculateTableData()
      }
    },
    async getMonthsChartData () {
      const monthsChart = await getMonthsChartApi(
        this.$router.history.current.query.fid,
        this.datepickerDate
      )
      if (monthsChart?.data?.success) {
        this.chartData = monthsChart.data.data
        this.drawChart()
      }
    },
    calculateTableData () {
      this.tableAccObj = {
        kWh: 0,
        peakWatt: 0,
        IRR: 0,
        PR: 0
      }
      this.tableAvgObj = {
        kWh: 0,
        peakWatt: 0,
        IRR: 0,
        PR: 0
      }
      this.tableData.forEach((x, idx) => {
        if (x.accKwh) {
          this.tableAccObj.kWh = dbDataHandle(this.tableAccObj.kWh + x.accKwh)
        }
        if (x.peakWatt) {
          this.tableAccObj.peakWatt = dbDataHandle(
            this.tableAccObj.peakWatt + x.peakWatt
          )
        }
        if (x.accIRR) {
          this.tableAccObj.IRR = dbDataHandle(this.tableAccObj.IRR + x.accIRR)
        }
        if (x.accPR) {
          this.tableAccObj.PR = dbDataHandle(this.tableAccObj.PR + x.accPR)
        }
        if (idx === this.tableData.length - 1) {
          this.tableAvgObj.kWh = dbDataHandle(
            this.tableAccObj.kWh / this.tableData.length
          )
          this.tableAvgObj.peakWatt = dbDataHandle(
            this.tableAccObj.peakWatt / this.tableData.length
          )
          this.tableAvgObj.IRR = dbDataHandle(
            this.tableAccObj.IRR / this.tableData.length
          )
          this.tableAvgObj.PR = dbDataHandle(
            (this.tableAccObj.PR / this.tableData.length) * 100
          )
        }
      })
    },
    downloadData () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.monthsChart.xAxis.categories)
      this.monthsChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.monthsChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.chartData.forEach((obj) => {
        download[0].data.push(obj.accKwh)
        download[1].data.push(obj.accIRR)
        download[2].data.push(obj.accPR * 100)
      })

      this.monthsChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })

      const fileName = `案場報表_${this.datepickerDate}`

      downloadCsv(downloadArr, fields, fileName)
    },
    drawChart () {
      this.monthsChart.xAxis.categories = []
      this.monthsChart.series.forEach((x) => {
        x.data = []
      })
      this.chartData.forEach((x) => {
        this.monthsChart.xAxis.categories.push(x.date)
        this.monthsChart.series[0].data.push(dbDataHandle(x.accKwh))
        this.monthsChart.series[1].data.push(dbDataHandle(x.accIRR))
        this.monthsChart.series[2].data.push(dbDataHandle(x.accPR * 100))
      })
      this.$nextTick(function () {
        this.$refs.chart.chart.hideLoading()
      })
    }
  },
  watch: {},
  async created () {},
  async mounted () {
    this.getSearch('month')
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/customVariables.scss";
table {
  width: 100% !important;
}
table th {
  padding: 5px 10px;
  text-align: center;
}
table td {
  padding: 5px 10px;
  text-align: center;
}
table td {
  font-size: 16px;
  font-weight: 700;
  border: solid 1px rgb(236, 236, 236);
}
.sideTable {
  table {
    width: 25% !important;
  }
  table td {
    // width: 50%;
    padding: 5px 10px;
    text-align: left;
  }
  table td {
    font-size: 16px;
    font-weight: 700;
    border: solid 1px rgb(236, 236, 236);
  }
}
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
  .row {
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/scss/DataAnalysis.scss";
.checkBoxStyle {
  display: flex;
  // border: 1px solid rgb(193, 215, 255);
  max-height: 110px;
  overflow: auto;
  padding: 5px;
  // margin: 5px 5px;
  // box-shadow: rgba(32, 143, 75, 0.35) 0px 0px 3px;
}
</style>
